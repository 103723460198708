<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
				</p>
				<div class="h-b"><el-button type="primary" size="small" @click="addUser"
						v-right-code="'Sysuser:Add'">新增</el-button> </div>
				<div class="h-b"><el-button type="text" size="small" @click="saveUser" v-loading="loadding"
						v-right-code="'Sysuser:Add'">保存</el-button> </div>
			</div>
		</div>
		<div class="form-list">
			<el-form ref="_userInfoForm" :model="dataSource" :rules="userCheckRule">
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>登录账号：</el-col>
					<el-col :span="7">
						<el-form-item prop="UserName">
							<el-input v-model.trim="dataSource.UserName" :maxlength="30" :minlength="4"
								:disabled="isDisabled" :readonly="isDisabled" placeholder="登录账号"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">昵称：</el-col>
					<el-col :span="7">
						<el-form-item>
							<el-input v-model.trim="dataSource.NickName" :maxlength="50" placeholder="昵称"
								autofocus="autofocus"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item v-if="!isDisabled">
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>登录密码：</el-col>
					<el-col :span="7">
						<el-form-item prop="UserPassword">
							<el-input type="password" v-model="dataSource.UserPassword" :maxlength="32" :minlength="8"
								placeholder="密码"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>确认密码：</el-col>
					<el-col :span="7">
						<el-form-item prop="ReUserPassword">
							<el-input type="password" v-model="dataSource.ReUserPassword" :maxlength="32" :minlength="8"
								placeholder="确认密码"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>姓名：</el-col>
					<el-col :span="7">
						<el-form-item prop="RealName">
							<el-input v-model.trim="dataSource.RealName" :maxlength="20" placeholder="姓名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">联系电话：</el-col>
					<el-col :span="7">
						<el-form-item prop="Mobile">
							<el-input v-model.number="dataSource.Mobile" :maxlength="11" placeholder="联系电话"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title">状态：</el-col>
					<el-col :span="7">
						<el-col :span="7">
							<el-form-item>
								<el-select v-model="dataSource.UserStatus" :maxlength="200">
									<el-option v-for="item in UserStatusList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-col>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>用户类型：</el-col>
					<el-col :span="7">
						<el-form-item prop="SysBizType">
							<el-select v-model="dataSource.SysBizType" :maxlength="200"
								@change="sysBizTypeChange(dataSource.SysBizType)"
								:disabled="!dataSource.IsChangeSysBizType">
								<el-option v-for="item in sysBizTypeList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<!--<el-col :span="3" class="form-title">后台权限过滤：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsRightFilter">
                            <el-switch v-model="dataSource.IsRightFilter" :disabled="dataSource.IsSuperAdmin" :on-value="true" :off-value="false" on-text="是" off-text="否"></el-switch>
                        </el-form-item>
                    </el-col>-->
				</el-form-item>
			</el-form>
		</div>

		<el-tabs type="border-card" v-model="tabActiveName">
			<el-tab-pane label="角色授权" name="tabRole">
				<div class="form-padding">
					<el-table v-loading="loadding" ref="_roleList" :data="dataSource.Roles"
						:default-sort="{ prop: 'RoleName' }" style="width: 100%" border
						@selection-change="handleSelectionChange" highlight-current-row>
						<el-table-column type="selection" width="55">
							<template slot-scope="scope">
								<el-checkbox v-model="scope.row.Checked"></el-checkbox>
							</template>
						</el-table-column>
						<el-table-column prop="RoleName" label="角色名称"></el-table-column>
						<el-table-column prop="IsSystemRole" label="系统角色">
							<template slot-scope="scope">
								<el-tag :type="scope.row.IsSystemRole ? 'success' : 'primary'" close-transition
									v-text="scope.row.IsSystemRole === true ? '是' : '否'"></el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="RoleDesc" label="描述"></el-table-column>
					</el-table>
				</div>
			</el-tab-pane>

			<el-tab-pane name="tabCustomer">
				<span slot="label"><i class="el-icon-date"></i> 客户管理</span>
				<div class="form-padding">
					<div class="h-handle-button">
						<div class="h-b">
							<el-button type="primary" size="small" @click="searchEnterpriseList">选择客户</el-button>
						</div>
					</div>
					<el-table :data="dataSource.EnterpriseInfos" border highlight-current-row>
						<el-table-column label="客户编码">
							<template slot-scope="scope">
								<span v-model="scope.row.EnterpriseCode">{{scope.row.EnterpriseCode}}</span>
							</template>
						</el-table-column>
						<el-table-column label="客户名称">
							<template slot-scope="scope">
								<span v-model="scope.row.EnterpriseName">{{scope.row.EnterpriseName}}</span>
							</template>
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="80">
							<template slot-scope="scope">
								<el-button @click="removeEnterprise(scope.row)" size="small" type="text">移除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-tab-pane>


		</el-tabs>
		<!--<el-tabs type="border-card" v-model="tabActiveName" @tab-click="tab2Click">
            <el-tab-pane name="tabgroupposition">
                <span slot="label"><i class="el-icon-date"></i> 部门职位</span>
                <div class="form-padding">
                    <div class="h-handle-button">
                        <div class="h-b">
                            <el-button type="primary" size="small" @click="searchGroupPositionList()">选择部门职位</el-button>
                        </div>
                    </div>
                    <el-table :data="dataSource.GroupPositions" border highlight-current-row>
                        <el-table-column label="部门名称">
                            <template slot-scope="scope">
                                <span v-model="scope.row.SysGroupName">{{scope.row.SysGroupName}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="职位名称">
                            <template slot-scope="scope">
                                <span v-model="scope.row.SysPositionName">{{scope.row.SysPositionName}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column fixed="right" label="操作" width="80">
                            <template slot-scope="scope">
                                <el-button @click="removeGroupPosition(scope.row)" size="small" type="text">移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane name="tabCustomer">
                <span slot="label"><i class="el-icon-date"></i> 客户管理</span>
                <div class="form-padding">
                    <div class="h-handle-button">
                        <div class="h-b">
                            <el-button type="primary" size="small" @click="searchCustomerList()">选择客户</el-button>
                        </div>
                    </div>
                    <el-table :data="dataSource.CustomerInfos" border highlight-current-row>
                        <el-table-column label="客户编码">
                            <template slot-scope="scope">
                                <span v-model="scope.row.CustomerCode">{{scope.row.CustomerCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="客户名称">
                            <template slot-scope="scope">
                                <span v-model="scope.row.CustomerName">{{scope.row.CustomerName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="80">
                            <template slot-scope="scope">
                                <el-button @click="removeCustomer(scope.row)" size="small" type="text">移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane name="tabMember">
                <span slot="label"><i class="el-icon-date"></i> 服务商管理</span>
                <div class="form-padding">
                    <div class="h-handle-button">
                        <div class="h-b">
                            <el-button type="primary" size="small" @click="searchMemberList()">选择服务商</el-button>
                        </div>
                    </div>
                    <el-table :data="dataSource.MemberInfos" border highlight-current-row>
                        <el-table-column label="服务商编码">
                            <template slot-scope="scope">
                                <span v-model="scope.row.MemberCode">{{scope.row.MemberCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="服务商名称">
                            <template slot-scope="scope">
                                <span v-model="scope.row.MemberName">{{scope.row.MemberName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="80">
                            <template slot-scope="scope">
                                <el-button @click="removeMember(scope.row)" size="small" type="text">移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
        </el-tabs>-->
		<!--<DialogEx :options="groupPositionOptions" title="选择部门职位" @onOk="onGroupPositionOk">
            <GroupPosition ref="groupPosition" :config="groupPositionConfig" @onSelectedRow="onGroupPositionSelectedRow" style="height:450px;"></GroupPosition>
        </DialogEx>

        <DialogEx :options="memberOptions" title="选择服务商" @onOk="onMemberOk">
            <Member ref="member" :config="memberConfig" @onSelectedRow="onMemberSelectedRow" style="height:450px;"></Member>
        </DialogEx>-->
		<DialogEx :options="enterpriseOptions" title="选择客户" @onOk="onEnterpriseOk">
			<Enterprise ref="enterprise" :config="enterpriseConfig" @onSelectedRow="onEnterpriseSelectedRow">
			</Enterprise>
		</DialogEx>
	</div>
</template>

<script>
	export default {
		data() {
			var checkUsername = function(rule, value, callback) {
				if (!value) return callback(new Error('登录账号不能为空'));
				callback();
			};
			var realname = function(rule, value, callback) {
				if (!value) return callback(new Error('姓名不能为空'));
				callback();
			};
			var nickname = function(rule, value, callback) {
				if (!value) return callback(new Error('昵称不能为空'));
				callback();
			};
			var checkPassword = (rule, value, callback) => {
				if (!this.isDisabled) {
					if (value === '' || !value) callback(new Error('请输入密码'))
					else {
						if (value.length < 8) {
							callback(new Error('密码至少八位或者八位以上'))
						}
						var patrn =
							/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*]+$)(?![\d!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/;
						if (!patrn.exec(value)) callback(new Error('密码必须包含数字，字母，特殊字符'))
						if (this.dataSource.ReUserPassword !== '') {
							this.$refs._userInfoForm.validateField('ReUserPassword');
						}
						callback();
					}
				} else callback();
			};

			var checkRepassword = (rule, value, callback) => {
				if (!this.isDisabled) {
					if (value === '' || !value) {
						callback(new Error('请再次输入密码'));
					} else if (value !== this.dataSource.UserPassword) {
						callback(new Error('两次输入密码不一致!'));
					} else {
						callback();
					}
				} else callback();
			};

			var checkMobile = (rule, value, callback) => {
				if (void 0 == value || !value || value.length == 0)
					callback();
				else {
					if (!this.Utils.isMobile(value))
						callback(new Error('联系电话无效.'));
					else
						callback();
				}
			};
			return {
				multipleSelection: [],
				reUserPassword: '',
				dataSourceCopy: {},
				loadding: false,
				isDisabled: false,
				tabActiveName: 'tabRole',
				currentEnterpriseSelectedRows: [],
				enterpriseOptions: {
					visible: false,
					size: 'small'
				},
				enterpriseConfig: {
					isDetailDisplay: true,
					isSelectionDisplay: true,
				},
				//currentCustomerSelectedRows: [],
				//currentMemberSelectedRows: [],
				//groupPositionOptions: {
				//    visible: false,
				//    size: 'small'
				//},
				//groupPositionConfig: {
				//    isDetailDisplay: true,
				//    isSelectionDisplay: true,
				//},         
				//memberOptions: {
				//    visible: false,
				//    size: 'small'
				//},
				//memberConfig: {
				//    isDetailDisplay: true,
				//    isSelectionDisplay: true,
				//},
				UserStatusList: [{
						label: '正常',
						value: "100"
					},
					{
						label: '停用',
						value: "400"
					}
				],
				userCheckRule: {
					UserName: [{
						validator: checkUsername,
						trigger: 'blur'
					}],
					UserPassword: [{
						validator: checkPassword,
						trigger: 'blur'
					}],
					ReUserPassword: [{
						validator: checkRepassword,
						trigger: 'blur'
					}],
					Mobile: [{
						validator: checkMobile,
						trigger: 'blur'
					}],
					RealName: [{
						validator: realname,
						trigger: 'blur'
					}],
					SysBizType: [{
						required: true,
						message: '请选择用户类型',
						trigger: 'blur'
					}]
				},
				sysBizTypeList: [
					{
						label: '通用',
						value: 300
					},
					{
						label: '业务',
						value: 200
					},
					{
						label: '系统',
						value: 100
					}
				]
			}
		},
		props: {
			config: {
				isDetailDisplay: false,
			},
			dataSource: {}
		},
		mounted() {
			this.Event.$on("clearEditUserForm", () => this.resetForm);
		},
		watch: {
			dataSource: {
				handler(curVal, oldVal) {
					this.isDisabled = this.dataSource.UserId && this.dataSource.UserId!= 0;
				},
				deep: true
			},
		},
		methods: {
			tab2Click: function(tab, event) {},
			handleSelectionChange(val) {

				if (val.length > 0)
					val.forEach((item, index) => item.Checked = true);
				else
					this.dataSource.Roles.forEach((item, index) => item.Checked = false);
			},
			addUser() {
				this.Event.$emit("onAddUser");
			},
			resetForm() {
				!this.isPassValidate && this.$refs['_userInfoForm'].resetFields(); //清空表单
			},
			//tab页-客户管理操作
			searchEnterpriseList: function() {
				this.enterpriseOptions.visible = true;
				//this.Event.$emit("refreshSelectGroupPositionForm");
			},
			onEnterpriseSelectedRow: function(data) {
				this.currentEnterpriseSelectedRows = data;
			},
			onEnterpriseOk: function() {
				var _this = this;
				var rows = _this.currentEnterpriseSelectedRows;
				if (!rows || rows.length == 0) return;
				if (!this.dataSource.EnterpriseInfos) {
					this.dataSource.EnterpriseInfos = [];
				}
				for (var i = 0; i < rows.length; i++) {
					var item = rows[i];
					var newRow = {
						OptEnterpriseID: item.OptEnterpriseID,
						EnterpriseCode: item.EnterpriseCode,
						EnterpriseName: item.EnterpriseName,
					};
					_this.dataSource.EnterpriseInfos.push(newRow);
				}
				//合并两个数组
				// arr1.push(...arr2)//或者arr1 = [...arr1,...arr2]

				//let arr3 = [...new Set(arr1)]
				_this.dataSource.EnterpriseInfos = _this.uniqueArrEnterprise(_this.dataSource.EnterpriseInfos);
				console.log(" _this.dataSource.EnterpriseInfos:", _this.dataSource.EnterpriseInfos);
			},
			removeEnterprise: function(row, index) {
				this.dataSource.EnterpriseInfos.remove(row);
			},
			////客户选择去重
			uniqueArrEnterprise: function(array) {
				var r = [];
				for (var i = 0, l = array.length; i < l; i++) {
					for (var j = i + 1; j < l; j++)
						if (array[i].EnterpriseCode === array[j].EnterpriseCode) j = ++i;
					r.push(array[i]);
				}
				return r;
			},
			//tab页-部门职位操作
			//searchGroupPositionList: function () {
			//    this.groupPositionOptions.visible = true;
			//    this.Event.$emit("refreshSelectGroupPositionForm");
			//},
			//onGroupPositionSelectedRow: function (data) {
			//    this.currentGroupPositionSelectedRows = data;
			//},
			//onGroupPositionOk: function () {
			//    var _this = this;
			//    var rows = _this.currentGroupPositionSelectedRows;
			//    if (!rows || rows.length == 0) return;
			//    _this.dataSource.GroupPositions.length = 0;
			//    for (var i = 0; i < rows.length; i++) {
			//        var item = rows[i];
			//        var newRow = {
			//            SysGroupPositionID: item.SysGroupPositionID,
			//            SysGroupName: item.SysGroupName,
			//            SysPositionName: item.SysPositionName,
			//        };
			//        _this.dataSource.GroupPositions.push(newRow);
			//    }
			//},
			//removeGroupPosition: function (row, index) {
			//    this.dataSource.GroupPositions.remove(row);
			//},
			//tab页-客户管理操作
			/*        searchCustomerList: function () {
			            this.customerOptions.visible = true;
			            //this.Event.$emit("refreshSelectGroupPositionForm");
			        },
			        onCustomerSelectedRow: function (data) {
			            this.currentCustomerSelectedRows = data;
			        },
			        onCustomerOk: function () {
			            var _this = this;
			            var rows = _this.currentCustomerSelectedRows;
			            if (!rows || rows.length == 0) return;
			            for (var i = 0; i < rows.length; i++) {
			                var item = rows[i];
			                var newRow = {
			                    CustomerID: item.CustomerID,
			                    CustomerName: item.CustomerName,
			                    CustomerCode: item.CustomerCode,
			                };
			                _this.dataSource.CustomerInfos.push(newRow);
			            }
			            _this.dataSource.CustomerInfos = _this.uniqueArrCustomer(_this.dataSource.CustomerInfos);
			        },
			        removeCustomer: function (row, index) {
			            this.dataSource.CustomerInfos.remove(row);
			        }, */
			////tab页-服务商管理操作
			//searchMemberList: function () {
			//    this.memberOptions.visible = true;
			//    //this.Event.$emit("refreshSelectGroupPositionForm");
			//},
			//onMemberSelectedRow: function (data) {
			//    this.currentMemberSelectedRows = data;
			//},
			//onMemberOk: function () {
			//    var _this = this;
			//    var rows = _this.currentMemberSelectedRows;
			//    if (!rows || rows.length == 0) return;

			//    for (var i = 0; i < rows.length; i++) {
			//        var item = rows[i];
			//        var newRow = {
			//            MemberId: item.MemberId,
			//            MemberName: item.MemberName,
			//            MemberCode: item.MemberCode,
			//        };
			//        _this.dataSource.MemberInfos.push(newRow);
			//    }
			//    _this.dataSource.MemberInfos = _this.uniqueArrMember(_this.dataSource.MemberInfos);
			//},
			//removeMember: function (row, index) {
			//    this.dataSource.MemberInfos.remove(row);
			//},
			////客户选择去重
			//uniqueArrCustomer: function (array) {
			//    var r = [];
			//    for (var i = 0, l = array.length; i < l; i++) {
			//        for (var j = i + 1; j < l; j++)
			//            if (array[i].CustomerCode === array[j].CustomerCode) j = ++i;
			//        r.push(array[i]);
			//    }
			//    return r;
			//},
			////服务商选择去重
			//uniqueArrMember: function (array) {
			//    var r = [];
			//    for (var i = 0, l = array.length; i < l; i++) {
			//        for (var j = i + 1; j < l; j++)
			//            if (array[i].MemberCode === array[j].MemberCode) j = ++i;
			//        r.push(array[i]);
			//    }
			//    return r;
			//},
			//保存
			saveUser() {
				var _this = this;
				_this.$refs["_userInfoForm"].validate((valid) => {
					_this.isPassValidate = valid;
					if (valid) {
						_this.$ajax.send("omsapi/sysuser/save", "post", _this.dataSource, (data) => {
							_this.dataSource.UserId === _this.Utils.emptyGuid && (_this.dataSource.UserId =
								data.Result);
							_this.$parent.syncDataSource();
							_this.Event.$emit("reloadUserPageList", data.Result);
							_this.Utils.messageBox("保存成功.", "success");
						});
					} else {
						return false;
					}
				});
			},
			sysBizTypeChange: function(sysBizType) {
				var _this = this;
				var id = _this.dataSource.UserId;
				_this.$ajax.send("omsapi/sysuser/initrolelist", "post", {
					UserId: id,
					SysBizType: sysBizType
				}, (data) => {
					if (data.IsSuccess && data.Result) {
						_this.dataSource.Roles = data.Result.Roles;
					}
				});
			}
		},
		components: {
			//"GroupPosition": resolve => { require(['@/components/business/selector/grouppositionselector.vue'], resolve) },
			// "Customer": resolve => { require(['@/components/business/selector/customerselector.vue'], resolve) },
			//"Member": resolve => { require(['@/components/business/selector/memberselector.vue'], resolve) },
			"Enterprise": resolve => {
				require(['@/components/business/selector/enterpriseselector.vue'], resolve)
			},
		}
	}
</script>

<style></style>